<template>
    <div class="express">
        <!-- 头部banner -->
        <banner :subtitle="subtitle" :maintitle="maintitle" :logoFlag="logoFlag" :imgSrc="imgSrc"></banner>
        <!-- 主体内容 -->
        <div class="express_cont">
            <div class="cont" v-class="'cont animate__animated animate__fadeInup animate__block'">
                <partTitle title="用车体验" subtitle="Experience"></partTitle>
                <div>
                    <swiper class="swiper first" :options="swiperOption">
                        <swiper-slide
                            v-for="(item,index) in swiper"
                            :key="index">
                            <div class="advantage-swiper_item">
                                <div class="bg">
                                    <img :src="item.icon" class="advantage-swiper_img"/>
                                </div>
                                <div class="advantage-swiper_bottom">
                                    <span>{{item.title}}</span>
                                    <!-- <p>{{item.mome}}</p> -->
                                </div>
                            </div>
                        </swiper-slide>
                    </swiper>
                    <!-- 如果需要分页器 -->
                    <div class="swiper-pagination"></div>
                </div>
            </div>
            <div class="cont" v-class="'cont animate__animated animate__fadeInup animate__block'">
                <partTitle title="我们的产品" subtitle="Product"></partTitle>
                <div>
                    <swiper class="swiper second" :options="swiperOption1">
                        <swiper-slide
                            v-for="(item,index) in swiper1"
                            :key="index">
                            <div class="advantage-swiper_item">
                                <div class="bg">
                                    <img :src="item.icon" class="advantage-swiper_img"/>
                                </div>
                                <div class="advantage-swiper_bottom">
                                    <span>{{item.title}}</span>
                                    <p>{{item.mome}}</p>
                                </div>
                            </div>
                        </swiper-slide>
                    </swiper>
                    <!-- 如果需要分页器 -->
                    <div class="swiper-pagination1"></div>
                </div>
            </div>
        </div>
        <!-- 底部 -->
        <foot></foot>
    </div>
</template>

<script>
export default {
    data() {
        return {
            swiperOption:{
                pagination: '.swiper-pagination',
                paginationClickable: true,
                loop: true
            },
            swiperOption1:{
                pagination: '.swiper-pagination1',
                paginationClickable: true,
                loop: true
            },
            logoFlag: false,
            maintitle: "顺风车",
            subtitle: "撮合车主与用户真实出行需求，共同倡行绿色环保、公益互助的免费合乘平台",
            imgSrc: require('@/assets/images/sfc-bg.png'),
            flag: false,
            swiper:[
                {
                    title:'免费合乘',
                    icon:require('../../../static/images/sfc/sfc-one.png'),
                    mome:'平台100%规范认证环节，保证车主资料齐全'
                },
                {
                    title:'公益互助',
                    icon:require('../../../static/images/sfc/sfc-two.png'),
                    mome:'出行即获平台赠送乘客意外险，多重保障'
                },
                {
                    title:'环保共创',
                    icon:require('../../../static/images/sfc/sfc-three.png'),
                    mome:'专人实时监控出行服务，快速响应用户反馈'
                }
            ],
            swiper1:[
                {
                    title:'两种角色，随心切换',
                    icon:require('../../../static/images/sfc/yw-one.png'),
                    mome:'告别路途乏味，乘车更省钱；认证车主，还能轻松赚油费'
                },
                {
                    title:'实时沟通，灵活行程',
                    icon:require('../../../static/images/sfc/yw-two.png'),
                    mome:'车主接单即可接通在线聊天，行程问题实时“面对面”沟通'
                },
                {
                    title:'预约出行，顺路接送',
                    icon:require('../../../static/images/sfc/yw-three.png'),
                    mome:'上班下班提前发布行程，车主乘客选择多，路径规划更轻松'
                }
            ]
        }
    },
    mounted(){
        this.routerTo(4);
    }
}
</script>

<style>
.mobile_swiper {
    position: relative;
}
.swiper .advantage-swiper_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: .43rem;
    color: #2D2D39;
}
.swiper .advantage-swiper_item .bg {
    background: #F4F4F4;
    display: flex;
    align-items: center;
    justify-content: center;
}
.swiper .advantage-swiper_item .advantage-swiper_bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 .48rem;
}
.swiper .advantage-swiper_item .advantage-swiper_bottom span {
    font-size: .36rem;
    margin: .5rem 0 .21rem;
}
.swiper .advantage-swiper_item .advantage-swiper_bottom p {
    font-size: .24rem;
}
.swiper-pagination,
.swiper-pagination1,
.swiper-pagination2 {
    position: relative;
    display: flex;
    justify-content: center;
    margin: .45rem auto;
}
.swiper-pagination .swiper-pagination-bullet,
.swiper-pagination1 .swiper-pagination-bullet,
.swiper-pagination2 .swiper-pagination-bullet {
    margin: 0 .1rem;
    background: #CBCBCB;
    opacity: 1;
}
.swiper-pagination-bullet-active {
    background: #676767!important;
    opacity: 1;
}
</style>
<style scoped>
.animate__block{
    visibility: visible !important;
}
.animate__animated{
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-duration: var(--animate-duration);
    animation-duration: var(--animate-duration);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-delay:0.2s;
}
.animate__fadeInup{
    -webkit-animation-name: FadeInup;
    animation-name: FadeInup;
}
.animate__animated {
  --animate-duration: .5s;
}
.express_cont {
    margin-bottom: 2rem;
}
.express .express_cont .cont {
    margin-top: .9rem;
    visibility: hidden;
}
.first .bg,
.first .bg img {
    width: 5.4rem;
    height: 3.1rem;
}
.second .bg,
.third .bg {
    width: 6.56rem;
    height: 3.07rem;
}
.second .bg img,
.third .bg img {
    height: 2.19rem;
}
@keyframes FadeInup {
    0%{
        opacity: 0;
        -webkit-transform: translateY(30%);
        transform: translateY(30%);
    }
    100%{
        opacity:1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
</style>